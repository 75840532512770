@import 'design/template/mixin_templates';

/*-----------------------------
    GENERAL
-------------------------------*/

*, html {
  outline: 0;
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height: 1.2;
}

@include titreBaseSize();

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  margin: 0;
  padding: 0;
}

h2, .h2 {
  font-size: 2.3em;
  letter-spacing: -.04em;
  font-family: Quicksand, sans-serif;
  font-weight: 400;
  margin: 0 0 15px;
}

h3, .h3 {
  font-size: 1.9em;
  letter-spacing: -.05em;
  margin: 0 0 15px;
}

input, select, textarea {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1em;
  padding: 3px;
}

input[type=submit] {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  border: none;
  font-size: 1em;
  margin-top: 20px;
  font-weight: 700;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  font-family: Quicksand, sans-serif;
  padding: 5px;
}

.clearer {
  clear: both;
}

img, a img {
  border: none;
}

input[type=submit]:hover, .galerie a, p.diaporama a, #contentpage .produits a.addtobasket:hover, #content_full .produits a.addtobasket:hover, #contentpage .produits a.addbasket:hover, #content_full .produits a.addbasket:hover, #home_products_wrapper .produits a.addtobasket:hover, #footerend a:hover, .filter a:hover, .current a {
  text-decoration: none;
}

iframe {
  max-width: 100%;
}

/*-----------------------------
    HEADER
-------------------------------*/

#header {
  position: relative;
  height: 150px;
}

.logo {
  display: inline-block;

  img {
    display: inline-block;
    max-width: 100%;
    max-height: 150px;
  }
}

.slogan {
  position: absolute;
  left: 390px;
  top: 60px;
  font-size: 2.3em;
  font-family: Quicksand, sans-serif;
  font-weight: lighter;
  min-height: 20px;
}

#left_bandeau {
  position: absolute;
  right: -110px;
  top: 65px;
  line-height: 2.3em;
  width: 90px;
  padding: 5px 10px;
}

#left_bandeau a {
  color: #FFF;
  text-decoration: none;
  font-size: .85em;
  font-weight: 700;
}

/*-----------------------------
    CONTENT
-------------------------------*/

.center {
  text-align: center;
}

#wrapper {
  width: 950px;
  min-height: 700px;
  position: relative;
  margin: 0 auto;
  padding: 25px 10px;
}

#home_products_wrapper {
  // height:580px;
  border: none;
  position: relative;
  width: 100%;
  margin: 0 0 20px;
  padding: 0;
}

#content {
  min-height: 500px;
}

#contentpage,
#content_full {
  width: 100%;
  margin: 0 0 35px;
}

#contentpage p,
#content_full p {
  line-height: 1.3em;
  font-size: 1.1em;
  margin: 0 0 15px;
}

#contentpage ul,
#content_full ul {
  list-style-position: inside;
}

.contentleft {
  width: 660px;
  float: left;
}

.contentright {
  width: 280px;
  float: right;
}

#contentpage .col3,
#content_full .col3 {
  float: left;
  width: 300px;
}

#contentpage .col5b,
#content_full .col5b {
  float: left;
  width: 150px;
}

#contentpage .col1, #contentpage .col2,
#content_full .col1, #content_full .col2 {
  float: left;
  width: 300px;
  margin-right: 40px;
}

#contentpage .col1b, #contentpage .col2b, #contentpage .col3b, #contentpage .col4b,
#content_full .col1b, #content_full .col2b, #content_full .col3b, #content_full .col4b {
  float: left;
  width: 150px;
  margin-right: 40px;
}

.contentright ul a, #footer ul a {
  text-decoration: none;
  border-radius: 3px;
  margin: 0;
  padding: 7px 10px;
}

.contentright ul, #footer ul {
  list-style-type: square;
  margin: 0 auto;
}

.contentright ul li, #footer ul li {
  list-style-type: none;
  display: inline;
  float: left;
  margin: 0 10px 20px;
}

/*-----------------------------
    MENU
-------------------------------*/

nav#nav-principal > ul li .home {
  padding: 2px 6px;
}

nav#nav-principal {
  width: 100%;
  display: block;
  height: auto;
  text-decoration: none;
  font-family: Quicksand, sans-serif;
  font-weight: 700;
  font-size: 1.3em;
  position: relative;
  border: 0;
  margin: 0;
  padding: 0;
}

nav#nav-principal ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

nav#nav-principal > ul li {
  float: left;
  text-align: center;
  width: auto !important;
}

nav#nav-principal > ul li a {
  text-decoration: none;
  display: block;
  padding: 10px 15px 10px;
}

nav#nav-principal > ul li a img {
  border: none;
  margin-bottom: -8px;
}

nav#nav-principal > ul li:hover > ul {
  display: block;
}

nav#nav-principal ul ul {
  top: 100%;
  position: absolute;
  z-index: 999;
  width: 140px;
}

nav#nav-principal ul ul ul {
  top: 0 !important;
  left: 100%;
}

nav#nav-principal span.home a {
  position: absolute;
  z-index: 999;
  left: 0;
  padding: 7px 15px 10px;
}

nav#nav-principal > ul .home_link {
  width: 30px;
  height: 44px;
  background: url(../images/imgd399series/home.png) no-repeat top left;
}

ul#menu li a {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYxIDY0LjE0MDk0OSwgMjAxMC8xMi8wNy0xMDo1NzowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNS4xIFdpbmRvd3MiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OThDNkE0Mzg1REZCMTFFMTkyRUNCRTU4N0ZEMTVBNEIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OThDNkE0Mzk1REZCMTFFMTkyRUNCRTU4N0ZEMTVBNEIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5OEM2QTQzNjVERkIxMUUxOTJFQ0JFNTg3RkQxNUE0QiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5OEM2QTQzNzVERkIxMUUxOTJFQ0JFNTg3RkQxNUE0QiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PinmruMAAABoSURBVHjaYvr//z8DDqwExGf+o4K7UHFcehgY8EkCsSCphhIykGRDiTGQJEOJNZBoQ5EjgFyAYigTA+VACYhXwTjUMPAeEIehGwgSZCSAhYD4LBbDXKE0BAxELFM1HVI1p5CVlwECDADyOZZrr/2D0wAAAABJRU5ErkJggg==) no-repeat left center;
  display: block;
  font-family: Quicksand, sans-serif;
  font-size: 1.2em;
  text-decoration: none;
  margin: 0;
  padding: 7px 10px 7px 25px;
}

ul#menu li a:hover {
  color: #fff;
  padding-left: 30px;
}

#menu-additionnel li:hover ul {
  display: block;
  position: absolute;
  z-index: 999;
  top: 25px;
  left: 0;
  width: 150px;
}

#menu-rubrique {
  line-height: 1.7em;
}

/*-----------------------------
    BOUTIQUE
-------------------------------*/

#contentpage .produits, #content_full .produits {
  width: 23%;
  float: left;
  border: 0;
  background: transparent;
  position: relative;
  margin: 0 1% 25px;
  padding: 0;
}

#contentpage .produits img,
#content_full .produits img {
  border: 0;
}

#contentpage .produits select,
#content_full .produits select {
  width: 100% !important;
  margin: 5px 0;
}

#contentpage .produits p.nomprod,
#contentpage .produits p.titreprod,
#content_full .produits p.nomprod,
#content_full .produits p.titreprod {
  font-size: 1.7em !important;
  text-align: left;
  margin: 5px 0 15px;
}

#contentpage .produits .prixprod,
#content_full .produits .prixprod {
  font-weight: 700;
  font-size: .9em;
  text-decoration: none;
  margin: 85px 0 0 3px;
  padding: 4px 7px;
}

#contentpage .produits .oldprix,
#content_full .produits .oldprix, .oldprix {
  color: red;
  text-decoration: line-through;
  font-size: .85em;
}

#contentpage .produits HR,
#content_full .produits HR {
  border: none;
  background: none;
  width: 214px;
  margin: 5px 3px;
  padding: 0;
}

#contentpage .produits a.addtobasket, #contentpage .produits a.addbasket, .button,
#content_full .produits a.addtobasket, #content_full .produits a.addbasket, .button {
  box-shadow: 0 1px 0 0 #000;
  border-radius: 3px;
  font-weight: 700;
  border: none;
  text-decoration: none;
  cursor: pointer;
  font-size: 1em;
  text-align: center;
  width: 220px;
  clear: both;
  display: inline-block;
  margin: 5px 0;
  padding: 6px 0;
  box-sizing: border-box;
}

.button {
  padding: 6px 16px;
}

#contentpage .produits strong,
#content_full .produits strong {
  font-size: 1.6em;
}

#contentpage .remise,
#content_full .remise, .remise {
  font-weight: 700 !important;
  font-size: .9em !important;
  text-decoration: none !important;
  text-align: center;
  margin: 0 0 0 10px;
  padding: 4px 7px !important;
}

#contentpage .old_price,
#content_full .old_price {
  padding: 4px 7px !important;
}

#home_products_wrapper {
  font-family: Quicksand, sans-serif;
}

#home_products_wrapper .produits {
  width: 100%;
  //float:left;
  border: none;
  //height:580px;
  background: transparent;
  position: relative;
  margin: 0 !important;
  padding: 0;
  text-align: center;
}

#home_products_wrapper .bx-window {
  height: 580px !important;
}

#home_products_wrapper .produits img {
  border: none;
  margin: 0;
  width: auto !important;
  max-width: 100%;
  max-height: 500px;
}

#home_products_wrapper .produits .descprod {
  font-size: 1em;
  line-height: 1.4em;
  margin: 20px 0;
}

#home_products_wrapper .produits select {
  width: 100%;
  margin-top: 5px;
  margin-right: 15px;
}

#home_products_wrapper .produits p.nomprod {
  position: absolute;
  background: url(/images/imgd399series/bg_nomproduit.png);
  width: auto;
  height: auto;
  top: 63px;
  left: 0;
  font-size: 2.2em !important;
  font-weight: lighter;
  letter-spacing: -.04em;
  padding: 10px 25px;
}

#home_products_wrapper .produits p.nomprod a {
  text-decoration: none;
  font-size: .6em !important;
  line-height: 1.5em;
}

#home_products_wrapper .produits p.prixprod {
  font-weight: 700;
  margin-top: 7px;
  font-size: 1em;
}

#home_products_wrapper .produits p.prixprod span {
  color: red;
  text-decoration: line-through;
  font-size: 1em;
  padding-left: 10px;
}

#home_products_wrapper .produits p.prixprod .prixprod-bg {
  font-weight: 700;
  margin-top: 85px;
  font-size: 1.3em;
  text-decoration: none;
  padding: 4px 7px;
}

#home_products_wrapper .remise, .remise {
  font-weight: 700 !important;
  font-size: 1.3em !important;
  text-decoration: none !important;
  text-align: center;
  margin: 0 0 0 10px;
  padding: 4px 7px !important;
}

#home_products_wrapper .produits HR {
  border: none;
  background: none;
  margin: 20px 0 10px;
  padding: 0;
}

#home_products_wrapper .produits a.addtobasket {
  margin-top: 5px;
  width: 130px;
  display: inline-block;
  font-weight: 700;
  text-decoration: none;
  font-size: 1em;
  text-align: center;
  padding: 6px 10px;
}

#home_products_wrapper .produits a.addtobasket:active {
  position: relative;
  top: 1px;
}

#boutiqueaccueil .produits p.attr {
  height: 35px !important;
}

#boutique_class .produits:hover {
  border: 1px solid #FFF;
}

#boutique_class .produits {
  width: 23%;
  float: left;
  position: relative;
  text-align: center;
  padding: 15px;
  box-sizing: border-box;
  margin: 10px 1%;
}

#boutique_class .produits .nomprod {
  display: block;
  margin-bottom: 10px;
}

#boutique_class .produits .attr {
  height: 20px;
  display: block;
}

#boutique_class .produits img {
  width: 100%;
  box-sizing: border-box;
  display: inline-block;
}

#boutique_class .prixprod {
  margin-top: 10px;
}

.produits select {
  width: 100%;
}

#boutique_class .produits p.prixprod {
  font-size: 1em;
  font-weight: 700;
  margin: 5px 0;
}

#boutique_class .produits p.prixprod span {
  font-size: .75em;
  text-decoration: line-through;
  color: red;
}

#boutique_class .produits .attr select {
  width: 140px;
}

.hors-stock {
  height: auto;
}

.remise {
  position: absolute;
  font-size: 10px;
  top: -10px;
  right: -10px;
  padding-top: 15px;
}

#boutique_class .addbasket {
  cursor: pointer;
  display: block;
  /*background:url(../images/ajouter_au_panier.png) no-repeat 5px 0;*/
  padding-left: 25px;
  padding-top: 4px;
  font-size: 1.2em;
  font-weight: bold;
  position: relative;
  width: auto;
  margin: 5px auto !important;
}

#boutique_class .addbasket:before {
  content: "\f07a";
  font-family: FontAwesome, sans-serif;
  margin-right: 7px;
  text-decoration: none;
  position: absolute;
  left: 6px;
}

#boutique_class .productImageWrap {
  min-height: 0;
}

.nomprod {
  font-weight: 700;
  font-size: .9em !important;
  line-height: 1em !important;
}

.produits p.titreprod {
  font-size: .9em !important;
  line-height: 1em !important;
}

.prixprod {
  font-weight: 700;
  font-size: 1.1em !important;
}

.produits img {
  margin: 0 !important;
  display: inline-block;
}

.produits p {
  margin: 5px 0 !important;
}

#paiementchoix .button:hover {
  border: none;
}

.produits h3,
.produits .h3 {
  font-size: 1.4em;
}

.boutique_header > p {
  padding: 15px 11px;
}

.produits .produit_etiquette {
  width: 168px;
  z-index: 100;
}

.produit_etiquette2 {
  bottom: 0;
}

 .produits .container-etiquette {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
}

#fiche-produit .produits .produit_etiquette {
  position: absolute;
}

#boutique_class .produits .produit_etiquette, #boutique_class .produits .produit_etiquette2 {
  width: 100%;
  z-index: 100;
}

.produits {
  float: left;
  margin: 15px 10px;
}

.productImageWrap > img {
  width: 100%;
}

#contentpage .productImageWrap > img,
#content_full .productImageWrap > img {
  width: 100%;
}

#contentpage .produits .produit_etiquette,
#content_full .produits .produit_etiquette {
  width: 100%;
}

.is_etiquette .remise {
  right: 20px;
  top: 124px;
  z-index: 100;
}

.remise {
  top: 100px;
  z-index: 2;
}


#fiche-produit .top_fiche .reservation .reservation__addbasket .block-addbasket {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  > .button {
    flex: 0 1 auto;
    margin: 15px 0;
    display: inline-block;

    .fa {
      margin-right: 10px;
    }
  }

  > .button + .button {
    margin-left: 15px;
  }

}

.modal {
  z-index: 2041;
}

.modal-backdrop {
  z-index: 2040;
}

/*-----------------------------
    GALERIE
-------------------------------*/

.bx-prev {
  position: absolute;
  z-index: 999;
  top: 0;
  left: -49px;
  padding: 10px 15px;
}

.bx-next {
  position: absolute;
  left: 831px;
  z-index: 999;
  top: 0;
  padding: 10px 15px;
}

#bg_slider {
  height: 581px;
  position: absolute;
  top: 159px;
}

#slider {
  width: 300px;
  height: 250px;
  background: silver;
}

#slider img {
  width: 300px;
}

.productImageWrap {
  min-height: 200px;
}

.galerie_size {
  width: 19.5%;
}

.galerie_gutter {
  width: .5%;
}

.galerie {
  float: left;
  position: relative;
  cursor: pointer;
  margin: 5px .25%;
  width: 19.5%;

  img {
    width: 100%;
  }

  > a {
    display: block;
  }

  .linkcoms {
    display: none;
  }

  .titrephoto {
    text-indent: -9999px;
    overflow: hidden;
    display: none;
  }

  &:hover {
    .linkcoms {
      display: block;
    }

    .titrephoto {
      display: block;
    }
  }
}

.galerie span {
  position: absolute;
  display: none;
  background: url(../images/imgd399series/bg_loupe.png) no-repeat center center;
  background-size: cover;
  width: 100%;
  height: 100%;
  top: 0;
  color: #FFF !important;
}

.linkcoms {
  position: absolute;
  bottom: 0;
  left: 10px;
  font-size: .7em;
  display: none;
  text-decoration: underline !important;
}

.linkcoms:hover {
  text-decoration: none !important;
}

p.diaporama {
  text-align: center;
  margin-top: 30px;
}

.contenu-article img {
  max-width: 100%;
  height: auto !important;
}

#contentpage .photos,
#content_full .photos {
  width: 122px;
  float: left;
  height: 145px;
  background: #FFF;
  text-align: center;
  margin: 0 0 25px;
  padding: 10px;
}

#contentpage .photos img,
#content_full .photos img {
  opacity: 0.5;
  filter: alpha(opacity=50);
}

#contentpage .photos a.addcommentaires,
#content_full .photos a.addcommentaires {
  margin-top: 7px;
  display: block;
  color: #000;
  font-size: 1em;
}

.banner_diaporama .bx-controls.bx-has-controls-direction {
  top: 50% !important;
}

/*-----------------------------
    CONTACT
-------------------------------*/

input[type=text], #addmsg select {
  padding: 5px;
}

#addmsg textarea {
  width: 400px;
  padding: 5px;
}

#message {
  width: 570px;
  height: 120px;
}

#contact #messagehaut {
  width: 460px;
  height: 263px;
}

#buttoncontactfull {
  margin-top: 0;
  margin-left: 310px;
}

.texthautcontact {
  margin-bottom: 20px;
  padding-bottom: 20px;
}

#col1contact {
  width: 340px;
  float: left;
}

#col2contact {
  width: 495px;
  float: right;
}

#col2contactfull {
  width: 460px;
  float: left;
}

#contact textarea {
  width: 340px;
}

/*-----------------------------
    LIVRE D'OR
-------------------------------*/

.message_lo {
  border-radius: 3px;
  margin-bottom: 15px;
  position: relative;
  margin-top: 20px;
  padding: 15px;
}

.message_lo h4,
.message_lo .h4 {
  font-size: 1em;
  margin: 0 0 15px;
}

.message_lo .note {
  position: absolute;
  right: 15px;
  top: 15px;
  border-radius: 20px;
  font-weight: 700;
  font-size: .8em !important;
  padding: 5px 15px;
}

.message_lo .message {
  margin-top: 10px;
  font-style: italic;
  margin-bottom: 30px;
}

.message_lo a.web {
  font-weight: 700;
  margin-bottom: 0;
  position: relative;
  display: block;
  height: 20px;
  padding: 3px 0 0;
}

.message_lo p {
  text-align: justify;
  margin-bottom: 5px;
  font: normal 1rem arial, serif;
}

.message_lo .message {
  text-align: justify;
  margin-bottom: 5px;
  font: normal 1rem arial, serif;
}

.message_lo p.note {
  position: absolute;
  right: 15px;
  top: 15px;
  padding-left: 25px;
  padding-top: 2px;
}

.message_lo p.web {
  text-align: justify;
  font-size: .8em;
  margin: 10px 0 5px;
}

a.addmsglo {
  display: block;
  margin-top: 20px;
  font-weight: 700;
  text-decoration: none;
  text-align: center;
  padding: 5px;
}

/*-----------------------------
    FOOTER
-------------------------------*/

.footercol1 {
  width: 150px;
  float: left;
}

.footercol3 {
  width: 300px;
  float: left;
}

#footer {
  text-align: center;
  margin: 20px 0;
  padding: 25px 0;
}

#footerend {
  padding-top: 20px;
  text-align: center;
  margin: 20px 0;
}

.footer a {
  font-size: .85em;
}

#footer ul li ul li a, nav#nav-principal > ul li ul li a {
  display: block;
  text-align: left;
  font-size: 12px;
}

#footer ul li:hover ul {
  display: block;
  position: absolute;
  z-index: 999;
  width: 150px;
  top: 20px;
  left: 10px;
}

.footercol2, #addmsgleft, #col1contactfull {
  width: 250px;
  float: left;
}

#footer ul li, nav#nav-principal > ul li, #menu-additionnel li {
  position: relative;
}

#footer ul li ul, nav#nav-principal > ul li ul, #menu-additionnel li ul {
  display: none;
}

#footer ul li ul li, nav#nav-principal > ul li ul li, label {
  display: block;
}

#footer ul li ul li, #footer ul li ul li a {
  margin: 0;
}

/*-----------------------------
    AUTRE
-------------------------------*/

.divider {
  border: none;
  background: none;
  margin: 15px 0 20px;
  padding: 0;
}

.group:after {
  visibility: hidden;
  display: block;
  content: "";
  clear: both;
  height: 0;
}

dl {
  margin: 0 0 15px;
}

dt {
  margin-right: 5px;
}

.filter li {
  float: left;
  list-style: none;
}

.filter li:after {
  content: "/";
}

.filter li:last-child:after {
  content: "";
}

.filter a {
  text-decoration: none;
  padding: 0 10px;
}

.portfolio li {
  float: left;
  border: none;
  list-style: none;
  width: 140px;
  height: 140px;
  overflow: hidden;
  margin: 0;
}

.portfolio li:nth-child(4n) {
  margin-right: 0;
}

.portfolio a {
  display: block;
  border: none;
  text-decoration: none;
  width: 140px;
}

.portfolio img {
  width: 140px;
  max-width: 140px;
  min-height: 100%;
  display: block;
  border: none;
}

.portfolio {
  margin: 15px 0;
}

#GoogleMapAffichage {
  color: #000 !important;
}

#left_bandeau img, dt, dd {
  float: left;
}

nav#nav-principal > ul li ul li {
  float: none;
}

nav#nav-principal > ul li a:hover, #contentpage a, #content_full a, p.diaporama a:hover, a:visited, #home_products_wrapper .produits p.nomprod a:hover, #boutique_class .addbasket:hover {
  text-decoration: underline;
}

#home_products ul, ul#menu {
  list-style-type: none;
}

* html .group, :first-child + html .group {
  zoom: 1;
}

/*-----------------------------
    FLUX PANIER
-------------------------------*/

#flux-panier.template_flux_panier.template_flux_panier_1 {
  padding-top: 20px;
  color: #000;

  .side-tabs.etapes {
    border-bottom: none;
  }

  h4 {
    margin: 0;
  }

  #connexion-inscription > .col.s6 {
    padding: 0;
    width: 100%;
    margin: 15px 0;
  }

  div#information .list_address {
    margin: 0 0 20px;
  }
}

/********************* A GARDER POUR LES MODELS ********************/

/*Gerer les images dans les tableaux*/
.links_p {
  position: relative;
}

#contentpage img,
#content_full img {
  max-width: 100% !important;
  height: auto !important;
  display: inline-block;
}

#contentpage .zoomPupImgWrapper > img, #contentpage .zoomWrapperImage > img,
#content_full .zoomPupImgWrapper > img, #content_full .zoomWrapperImage > img {
  max-width: none !important;
}

table, tbody, tr {
  position: relative;
  table-layout: fixed;
  max-width: 100%;
}

#contentpage td img,
#content_full td img {
  display: table-cell;
  max-width: 100% !important;
}

#footer.footer_p .links_p td img, #footer.footer_p .links_p img {
  width: auto !important;
  max-width: 100% !important;
}

.links_p table {
  max-width: 100% !important;
}

/*Titre des commentaires du livre d'or ne prend pas toute la largeur*/
.message_lo h4,
.message_lo .h4 {
  width: 80%;
}

.container-comment, .content-cmonsite:not(.form-search) {
  overflow: hidden;
}

/*Slider*/
#sliderContent, .sliderImage, .sliderImage span, .sliderImage li {
  width: 100% !important;
}

.sliderImage {
  text-align: center !important;
}

.sliderImage img {
  text-align: center;
  width: auto;
  max-width: 100% !important;
}

.produits, .galerie {
  box-sizing: border-box;
}

.preview {
  max-width: 95%;
}

#fiche-produit {
  .wrap-description {
    .brand {
      color: inherit !important;
    }
  }

  .wrap-images {
    .grande-image {
      > a > img {
        max-height: none;
        width: 100%;
      }
    }
  }
}

/* Commandes */

#order .commandes td {
  padding: 10px 0;
  border-top: 1px solid rgba(128, 128, 128, .1);

  .button {
    text-decoration: none;
  }
}

#order .commandes-details {
  > td > div {
    border-bottom: none;
  }

  .infosclient.s6 {
    &, &:first-child {
      width: 100%;
      margin: 10px 0 0;
    }
  }
}

#order {
  .button {
    width: auto;
  }
}

/******************************************\
|************* MEDIA QUERIES **************|
\******************************************/

@media screen and (max-width: 1250px) {
  #wrapper {
    width: 980px !important;
    box-sizing: border-box;
  }
  #boutiqueaccueil .bx-window, #boutiqueaccueil .bx-wrapper {
    width: 100% !important;
  }
  .contentleft {
    width: 614px;
  }
  #col1 {
    width: 525px !important;
  }
  nav#nav-principal > ul {
    margin: 0 -10px 0 0;
  }
  .slidershadow {
    width: 100%;
  }
  #footer img {
    max-width: 100%;
    height: auto;
  }
  .search-bar {
    width: 95%;
  }
}

@media screen and (max-width: 990px) {
  #wrapper {
    width: 768px !important;
  }
  .contentleft, .contentright {
    width: 100% !important;
  }
  #slider {
    width: 100% !important;
    height: 450px !important;
  }
  #sliderContent .sliderImage img {
    width: auto !important;
  }
  .produits {
    margin: 0 13px 20px !important;
  }
  nav#nav-principal > ul {
    margin: 0 -21px;
  }
  #top-content {
    padding: 0 48px;
  }

  .coin-slider {
    margin-bottom: 20px;
  }
  #contentpage_center {
    overflow: hidden;
  }
  /*Suppression des blocs en dehors du contenu*/
  #block-flottant-search, #minisite-sidebar, #left_bandeau {
    display: none;
  }
  .bx-wrapper {
    height: auto;
    width: 100%;
    margin: 10px auto;
  }
  .bx-wrapper a.one_image {
    height: 150px;
  }

  #boutique_class .produits, #contentpage .produits, #content_full .produits {
    width: 31%;
    margin: 0 5px 20px !important;
  }
  #boutique_class .produits img {
    margin: 0 !important;
  }
  #boutique_class .produits h3,
  #boutique_class .produits .h3 {
    font-size: 1.3em;
  }

  /*Position des deux blocs centraux*/
  .categorie, .div-content {
    float: none;
    display: block;
    margin: 3%;
    width: 94%;
    box-sizing: border-box;
    padding: 1%;
  }

  .galerie_size {
    width: 32.5%;
  }
  .galerie_gutter {
    width: .5%;
  }
  .galerie {
    width: 32.5%;
  }

}

@media screen and (max-width: 768px) {
  #header {
    height: auto;
    width: 100%;
  }
  .slogan {
    left: 0 !important;
    top: 0 !important;
    right: auto !important;
    bottom: auto !important;
    text-align: center !important;
    font-size: 18px !important;
    width: 100% !important;
    position: relative !important;
    margin-bottom: 15px !important;
    height: auto !important;
  }
  .logo {
    padding-bottom: 15px;
    position: static !important;
    display: block !important;
    margin: 0 auto !important;
    padding-top: 15px;
    height: auto !important;
    text-align: center;

    img {
      max-height: 70px !important;
      width: auto !important;
      max-width: 100% !important;
      height: auto !important;
      position: static !important;

    }
  }
  #wrapper {
    width: 360px !important;
  }
  #wrapper nav#nav-principal > ul li {
    height: auto;
  }
  .search-bar {
    width: 90%;
  }
  #slider {
    height: 150px !important;
  }

  #header {
    margin: 0 10px;
  }
  #top-content {
    padding: 0 10px;
  }
  #content {
    padding: 1px 0 15px;
  }
  /*Fiche produit*/
  #fiche-produit #formulaire .form-group {
    float: none !important;
  }
  #fiche-produit .input-form-petit {
    width: auto !important;
  }
  #fiche-produit .input-form-moyen {
    width: 100% !important;
  }
  #fiche-produit .wrap-images, #fiche-produit .wrap-description {
    width: 100% !important;
    float: none !important;
  }

  .slogan {
    left: 10%;
    text-align: center;
    top: 75px;
    font-size: 18px;
    width: 80%;
  }

  #boutique_class .produits, #contentpage .produits, #content_full .produits {
    width: 90%;
    margin: 10px 5% !important;
    padding: 10px;
    position: relative;
  }
  .productImageWrap {
    text-align: center;
  }
  .produits img {
    display: inline-block;
  }

  form .row .col.s3 {
    width: 25%;
  }
  .form-group textarea {
    width: 100% !important;
  }
  .thumbnail {
    float: left;
    width: 42%;
    margin: 2.5%;
  }

  @import 'design/helper/responsive/mobile/mobile';

  .ui-datepicker {
    width: 98% !important;
    font-size: .75em;
    left: 0 !important;
  }

  .message_lo {
    margin: 15px 5px;
  }
  .search-bar {
    width: 85%;
  }
  #panier {
    right: 0 !important;
    width: 100% !important;
    box-sizing: border-box;
  }
  .ui-datepicker {
    left: 0 !important;
  }
  a.addmsglo {
    width: 100%;
    box-sizing: border-box;
  }


  .produits-base, .galerie-base {
    width: 75% !important;
    margin: 0 auto 12px !important;
    padding: 15px;
    float: none;
    display: block;
  }
  #carousel .produits-base {
    width: 150px !important;
  }
  .thumbnail {
    float: none;
    width: 75%;
    margin: 0 auto 12px;
    padding: 15px;
  }
  .content-payment-global {
    font-size: 15px !important;
  }
  a.addbasket.button {
    display: inline-block !important;
    padding-left: 15px;
    width: auto !important;
  }
  #wrapper .in {
    padding: 15px 8px;
  }
  ul.sous_navigation {
    margin: 15px 0 15px 10px;
  }
  .message_lo {
    margin: 15px 0;
  }
  #content form {
    overflow: hidden;
  }
  form .row .col {
    padding: 0 2px;
  }
  form .row .col.s3 {
    width: 50%;
  }

  .row .col.s6 {
    width: 100%;
  }
  #home_products_wrapper .produits p.nomprod {
    font-size: 1.5em !important;
  }

  .galerie_size {
    width: 49.5%;
  }
  .galerie_gutter {
    width: .5%;
  }
  .galerie {
    width: 49.5%;
  }
  //Template Marque
  .template_marque_1 {
    .marque-group {
      @include elemTailleCol(1, 0, true);
    }
  }
  .template_marque_2 {
    .marque-el {
      @include elemTailleCol(1, 0, true);
    }
  }
}

@media screen and (max-width: 400px) {
  #wrapper {
    width: 95% !important;
    box-sizing: border-box;
  }

  .galerie_size {
    width: 100%;
  }
  .galerie_gutter {
    width: 0;
  }
  .galerie {
    width: 100%;
  }

}

@media screen and (min-width: 768px) {
  nav#nav-principal > ul > ul li:hover > ul {
    display: block !important;
  }
  .menu_burger {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .menu_burger {
    text-align: center;
    font-size: 1.2em !important;
  }
  nav#nav-principal > ul > ul {
    position: relative;
  }
  /* Nav mobile */
  nav#nav-principal > ul {
    margin: 0;
    display: none;
  }
  nav#nav-principal > ul li {
    float: none;
    z-index: auto !important;
    position: relative;
    width: 100% !important;
    padding: 0;
    text-align: center;
  }
  nav#nav-principal > ul > li {
    border: none;
    width: 100%;
    display: block;
    margin: 0;
    position: relative;
    box-sizing: border-box;
  }
  .menu_burger {
    display: block;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    padding: 10px 5px;
    width: 100%;
    position: relative;
    z-index: 9999;
    box-sizing: border-box;
  }
  .menu_burger::before {
    font-family: FontAwesome, sans-serif;
    content: "\f0c9";
    margin-right: 5px;
  }
  nav#nav-principal.nav_fermee > ul {
    display: none;
  }
  nav#nav-principal.nav_ouverte > ul {
    display: block;
    width: 100%;
    box-sizing: border-box;
    border: none;
    margin: 0 !important;
    float: none;
  }
  nav#nav-principal > ul li {
    margin-right: 0;
    display: block;
    position: relative;
    overflow: visible;
    text-align: center;
    box-sizing: border-box;
  }
  nav#nav-principal > ul li a {
    height: auto;
    float: none !important;
    margin: 0 auto !important;
    top: 0 !important;
    width: 100% !important;
    display: inline-block;
    text-align: center;
    box-sizing: border-box;
    padding: 10px 0;
  }
  nav#nav-principal > ul li a span {
    font-size: 17px;
  }
  .hasDropdown ul + span::after {
    font-family: FontAwesome, sans-serif;
    content: "\f0d7";
  }
  .dropDown {
    position: absolute;
    right: 2%;
    top: 0;
    font-weight: bold;
    height: 31px;
    width: 10%;
    cursor: pointer;
    text-align: center;
    font-size: 1.2rem;
    padding-top: 5px;
    background-color: transparent !important;
    display: block !important;
    opacity: 1 !important;
  }
  nav#nav-principal > ul li ul {
    position: absolute;
    z-index: 9999;
  }
  nav#nav-principal > ul li ul li a {
    padding: 15px 0;
    text-align: center;
  }
  nav#nav-principal > ul li ul li {
    text-align: center;
    border-bottom: none;
    margin: 0;
  }
  nav#nav-principal > ul > li > a.current, nav#nav-principal > ul li a:hover {
    background: transparent;
  }
  .sousmenu_ouvert {
    z-index: 99999 !important;
  }
  nav#nav-principal > ul li.sousmenu_ouvert > ul {
    display: block;
    position: absolute;
    z-index: 9999;
    left: 0;
    padding: 2px;
    box-sizing: border-box;
    top: 100% !important;
    height: auto !important;
    width: 100% !important;
    visibility: visible !important;
  }
  nav#nav-principal > ul ul li.sousmenu_ouvert > ul li a {
    margin: 5px;
    color: #fff;
  }
  nav#nav-principal > ul ul li.sousmenu_ouvert > ul li a:hover {
    text-decoration: none;
    color: #fff;
  }
  /* FIN Nav mobile */
}

.nav-static-breadcrumb {
  margin-top: 10px;
  margin-bottom: 15px;
}

.nav-static-breadcrumb li:not(:last-child)::after {
  content: ' > ';
}

#fiche-produit .wrap-description .ajouter-panier .quantite {
  margin-right: 0;
}

.wrap-description .bloc-quantite .bloc-bt-quantity .bt-quantity {
  height: auto;
}